import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/bestpractice/bestpractice/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "geoserver"
    }}>{`Geoserver`}</h1>
    <p>{`Hosts various geospatial datasets: implements WMS and WFS.`}</p>
    <p>{`GeoServer is a Java-based software server that allows users to view and edit geospatial data. Using open standards set forth by the Open Geospatial Consortium (OGC), GeoServer allows for great flexibility in map creation and data sharing.`}</p>
    <ul>
      <li parentName="ul">{`API location: `}<a parentName="li" {...{
          "href": "https://kartta.hel.fi/ws/geoserver/avoindata/"
        }}>{`kartta.hel.fi/ws/geoserver/avoindata/`}</a></li>
    </ul>
    <h2 {...{
      "id": "documentation"
    }}>{`Documentation`}</h2>
    <p>{`The API documentation can be found on the hel.fi website `}<a parentName="p" {...{
        "href": "https://www.hel.fi/helsinki/fi/kartat-ja-liikenne/kartat-ja-paikkatieto/Paikkatiedot+ja+-aineistot/avoimet+paikkatiedot/"
      }}>{`in Finnish`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.hel.fi/helsinki/en/maps-and-transport/city-maps-and-gis/geographic-information-data/open-geographic-data"
      }}>{`English`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      